.product-card .product-card__image {
  position: absolute;
  top: -3px;
  left: -3px;
  max-width: calc(100% + 6px);
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  transition-timing-function: ease;
  transition-property: transform, visibility, opacity;
  transition-duration: 1.2s;
  object-fit: cover;
}
.product-card .product-card__image.product-card__image--alternate {
  visibility: hidden;
  opacity: 0;
}
.product-card .product-card__hover {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 0;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  padding: 0 20px;
  transform: translateX(-50%);
  transition-timing-function: ease-in;
  transition-duration: 0.3s;
  transition-property: opacity;
}
.product-card .product-card__image-wrapper {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
  .product-card .product-card__image-wrapper.product-card__image-wrapper--zoom:hover .product-card__image-container::after {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
  }
  .product-card .product-card__image-wrapper.product-card__image-wrapper--zoom:hover .product-card__image-container .product-card__image {
    transform: scale(1.15);
  }
}
@media (-moz-touch-enabled: 0), (hover: hover) {
  .product-card .product-card__image-wrapper.product-card__image-wrapper--enable-alt-image:hover .product-card__image {
    visibility: hidden;
    opacity: 0;
  }
  .product-card .product-card__image-wrapper.product-card__image-wrapper--enable-alt-image:hover .product-card__image.product-card__image--alternate {
    visibility: visible;
    opacity: 1;
  }
}
@media (-moz-touch-enabled: 0), (hover: hover) {
  .product-card .product-card__image-wrapper:hover .product-card__hover {
    visibility: visible;
    opacity: 0.9;
  }
}
.product-card .product-card__image-container {
  position: relative;
  display: block;
  padding-top: 150.35533%;
  background-color: #dddddd;
  text-decoration: unset;
}
.product-card .product-card__image-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.product-card .product-card__image-container:focus-visible .product-card__image {
  opacity: 0.6;
}
.product-card .product-card__button {
  min-width: 205px;
  width: 100%;
  padding: 16px 30px;
  background-color: #ffffff;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.08em;
}
.product-card .product-card__button[disabled] {
  cursor: default;
}
.product-card .product-card__radio-fieldset {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: default;
}
.product-card .product-card__options-container {
  width: 100%;
  margin: 0 -5px;
  padding: 10px 30px;
  background-color: #ffffff;
  text-align: center;
}
.product-card .product-card__radio-button-value {
  padding: 3px;
  transition-property: background;
  transition-timing-function: ease-in;
  transition-duration: 0.3s;
}
.product-card .product-card__radio-button {
  display: inline-block;
  padding: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.08em;
  color: #2b2b2b;
}
.product-card .product-card__radio-button label {
  cursor: pointer;
}
.product-card .product-card__radio-button input:disabled + label {
  position: relative;
  text-decoration: line-through;
  color: #666666;
  cursor: default;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
  .product-card .product-card__radio-button input:not(:disabled) + label:hover .product-card__radio-button-value {
    background: #dddddd;
  }
}
.product-card .product-card__option-radio {
  display: none;
}
.product-card .product-card__preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px 30px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.08em;
  text-align: center;
  color: #2b2b2b;
}
.product-card .product-card__error {
  width: 100%;
  padding: 16px 30px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.08em;
  text-align: center;
  color: #2b2b2b;
}
.product-card .product-card__preloader-icon {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 7px;
  border: 3px solid rgba(43, 43, 43, 0.4);
  border-radius: 50%;
  vertical-align: middle;
}
.product-card .product-card__preloader-icon::after {
  content: "";
  position: absolute;
  top: -3px;
  bottom: -3px;
  left: -3px;
  right: -3px;
  border: 3px solid transparent;
  border-radius: 50%;
  border-top-color: #ffffff;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
.product-card .product-card__badge-container {
  position: absolute;
  top: 2px;
  left: 2px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.product-card .product-card__badge-text {
  vertical-align: middle;
  font-family: "GT Super Ds", sans-serif;
  font-size: 12px;
  line-height: 1;
  text-decoration: unset;
  color: #2b2b2b;
}
.product-card .product-card__badge {
  display: inline-block;
  padding: 6px 17px;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
}
.product-card .product-card__badge.product-card__badge--red-sale {
  background: rgba(255, 0, 0, 0.8);
}
.product-card .product-card__badge.product-card__badge--red-sale .product-card__badge-text {
  text-transform: uppercase;
  color: #ffffff;
}
.product-card .product-card__badge svg,
.product-card .product-card__badge img {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}
.product-card .product-card__info {
  position: relative;
  margin-top: 11px;
}
.product-card .product-card__info-body {
  text-align: left;
}
.product-card .product-card__info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}
.product-card .product-card__products-count,
.product-card .product-card__color {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
}
.product-card .product-card__products-count {
  padding-left: 25px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  white-space: nowrap;
}
.product-card .product-card__color {
  overflow: hidden;
  margin-top: 2px;
  line-height: 1.43;
  letter-spacing: 0.08em;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.product-card .product-card__heading {
  overflow: hidden;
  display: -webkit-box;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.08em;
  text-overflow: ellipsis;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-decoration: unset;
  color: #2b2b2b;
}
.product-card .product-card__heading::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.product-card .product-card__heading:focus-visible::before {
  outline: 1px solid #2b2b2b;
}
.product-card .product-card__price {
  margin-top: 2px;
}
.product-card .product-card__price-value {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: 0.01em;
  color: #2b2b2b;
}
.product-card .product-card__price-value.product-card__price-value--sale {
  color: red;
}
.product-card .product-card__price-value.product-card__price-value--compared {
  margin-left: 8px;
  text-decoration: line-through;
  color: #666666;
}
.product-card .product-card__hidden-select {
  display: none;
}
@media (max-width: 991px) {
  .product-card .product-card__hover {
    display: none;
  }
}
@media (max-width: 575px) {
  .product-card .product-card__badge {
    display: flex;
    padding: 5px 14px;
    padding-top: 5px;
    padding-bottom: 4px;
    padding-left: 14px;
    padding-right: 14px;
  }
  .product-card .product-card__badge-text {
    margin-top: 1px;
    line-height: 1;
  }
  .product-card .product-card__products-count,
  .product-card .product-card__color {
    font-size: 10px;
  }
}
@media (max-width: 480px) {
  .product-card .product-card__hover {
    padding-left: 10px;
    padding-right: 10px;
  }
  .product-card .product-card__button {
    min-width: 130px;
    font-size: 12px;
  }
  .product-card .product-card__radio-button {
    font-size: 12px;
  }
  .product-card .product-card__radio-button-value {
    padding: 5px;
  }
  .product-card .product-card__preloader {
    font-size: 12px;
  }
  .product-card .product-card__info {
    margin-top: 10px;
  }
  .product-card .product-card__products-count {
    padding-left: 5px;
    font-size: 10px;
    line-height: 1.2;
  }
  .product-card .product-card__heading {
    font-size: 12px;
    line-height: 1.43;
  }
  .product-card .product-card__color {
    font-size: 10px;
    line-height: 1.33;
  }
  .product-card .product-card__price {
    margin-top: 0;
  }
  .product-card .product-card__price-value {
    font-size: 12px;
    line-height: 1.33;
  }
  .product-card .product-card__badge {
    padding: 4px 8px;
    padding-top: 4px;
    padding-bottom: 3px;
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (max-width: 380px) {
  .product-card .product-card__badge {
    padding-left: 6px;
    padding-right: 6px;
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}